import { Auth } from '@aws-amplify/auth'
import { useEffect } from 'react'
import useLocation from './useLocation'

export const useAuthConfig = () => {
	const location = useLocation()
	const baseUrl = location?.origin ?? ''
	useEffect(() => {
		Auth.configure({
			userPoolId: 'eu-central-1_8k29P2fbZ',
			userPoolWebClientId: '7lsh87nmuemknk1g2h0irlen1p',
			region: 'eu-central-1',
			mandatorySignIn: true,
			oauth: {
				domain: 'internal-auth.climatetest.de',
				scope: ['email', 'openid', 'profile'],
				redirectSignIn: `${baseUrl}/callback/login`,
				redirectSignOut: `${baseUrl}/callback/logout`,
				responseType: 'token',
			},
		})
	}, [baseUrl])
}
