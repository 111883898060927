import { Global, ThemeProvider } from '@emotion/react'
import { AppProps } from 'next/app'
import { Slide, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { useAuthConfig } from '../hooks/useAuthConfig'
import { getGlobalStyles, theme } from '../services/theme'

function MyApp({ Component, pageProps }: AppProps) {
	useAuthConfig()
	return (
		<ThemeProvider theme={theme}>
			<Global styles={getGlobalStyles} />
			<Component {...pageProps} />
			<ToastContainer hideProgressBar={true} closeOnClick={true} transition={Slide} />
		</ThemeProvider>
	)
}

export default MyApp
