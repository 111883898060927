import '@emotion/react'
import { CSSObject } from '@emotion/react'

export const theme = {
	spacings: {
		xs: '2px',
		s: '5px',
		m: '10px',
		l: '15px',
		xl: '20px',
		xxl: '30px',
		xxxl: '50px',
		xxxxl: '70px',
		paragraph: '1rem',
		pagePadding: 20,
		buttonPaddingVertical: '12px',
		buttonPaddingHorizontal: '15px',
	},
	layout: {
		maxPageWidth: 1440,
	},
	borderRadiuses: {
		default: '3px',
		large: '6px',
	},
	fontFamilies: {
		main: "'Roboto Mono', monospace",
	},
	fontSizes: {
		large: '20px',
		default: '15px',
		small: '13px',
		tiny: '11px',
	},
	fontWeights: {
		normal: 400,
		bold: 700,
	},
	lineHeights: {
		default: 1.6,
	},
	boxShadows: {
		button: `0px 2px 0px var(--black)`,
		buttonHover: `0px 1px 0px var(--black)`,
		input: `0px 2px 0px var(--black)`,
		inputFocus: `0px 2px 0px var(--blue)`,
	},
	colors: {
		pageBackground: 'var(--white)',
		black: 'var(--black)',
		blue: 'var(--blue)',
		gray300: 'var(--gray300)',
		gray400: 'var(--gray400)',
		gray500: 'var(--gray500)',
		gray700: 'var(--gray700)',
		gray800: 'var(--gray800)',
		gray900: 'var(--gray900)',
		warning: 'var(--warning)',
		error: 'var(--error)',
		selectionBackground: 'var(--selectionBackground)',
	},
}

export type Theme = typeof theme

export function getGlobalStyles(theme: Theme): CSSObject {
	return {
		':root': {
			'--white': '#fff',
			'--black': '#000',
			'--blue': '#4a90e2',
			'--gray300': '#f8f9f9',
			'--gray400': '#f2f2f4',
			'--gray500': '#e5e5e8',
			'--gray700': '#b0b2bc',
			'--gray800': '#92939c',
			'--gray900': '#62636b',
			'--warning': '#ffff9d',
			'--error': '#e74c3c',
			'--selectionBackground': 'rgba(0, 0, 0, .1)',
		},
		'@media (prefers-color-scheme: dark)': {
			':root': {
				'--white': '#111',
				'--black': '#eee',
				'--blue': '#1d53b5',
				'--gray300': '#070606',
				'--gray400': '#0d0d0b',
				'--gray500': '#1a1a17',
				'--gray700': '#4f4d43',
				'--gray800': '#6d6c63',
				'--gray900': '#9d9c94',
				'--warning': '#000062',
				'--error': '#e74c3c',
				'--selectionBackground': 'rgba(240, 240, 240, .1)',
			},
		},
		'*, *::before, *::after': {
			boxSizing: 'border-box',
		},
		'html, body': {
			margin: 0,
			padding: 0,
			fontFamily: theme.fontFamilies.main,
			fontSize: theme.fontSizes.default,
			lineHeight: theme.lineHeights.default,
			color: theme.colors.black,
			backgroundColor: theme.colors.pageBackground,
		},
		main: {
			display: 'block',
		},
		a: {
			color: theme.colors.black,
			textDecoration: 'underline',
			'&:hover': {
				color: theme.colors.black,
				textDecoration: 'none',
			},
		},
		p: {
			margin: 0,
			wordBreak: 'break-word',
			'& + &': {
				marginTop: theme.spacings.paragraph,
			},
		},
		'ul, ol': {
			listStyle: 'none',
			margin: 0,
			padding: 0,
		},
		li: {
			lineHeight: theme.lineHeights.default,
		},
		figure: {
			margin: 0,
		},
		img: {
			display: 'block',
			maxWidth: '100%',
			height: 'auto',
			borderStyle: 'none',
		},
		small: {
			fontSize: theme.fontSizes.small,
		},
		'input, button, textarea, select': {
			fontFamily: 'inherit',
			fontSize: '100%',
		},
		input: {
			width: '100%',
			padding: theme.spacings.m,
			border: `1px solid ${theme.colors.black}`,
			borderRadius: theme.borderRadiuses.default,
			backgroundColor: theme.colors.pageBackground,
			color: theme.colors.black,
			fontFamily: theme.fontFamilies.main,
			fontSize: theme.fontSizes.default,
			lineHeight: 1.0,
			boxShadow: theme.boxShadows.input,
			outline: 'none',
			'&:focus': {
				border: `1px solid ${theme.colors.blue}`,
				boxShadow: theme.boxShadows.inputFocus,
			},
			'::placeholder': {
				color: theme.colors.gray700,
			},
			'&[type=text], &[type=number], &[type=email], &[type=search], &[type=url]': {
				appearance: 'none',
			},
		},
		table: {
			borderCollapse: 'collapse',
			borderSpacing: 0,
		},
		/* WebKit/Blink Browsers */
		'::selection': {
			background: theme.colors.selectionBackground,
		},
		/* Gecko Browsers */
		'::-moz-selection': {
			background: theme.colors.selectionBackground,
		},
	}
}
